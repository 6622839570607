<template>
  <div class="Pigeonhole">
    <div style="text-align: center; font-size: 30px; margin-bottom: 20px">
      归档
    </div>
    <el-timeline style="max-width: 100%">
      <el-timeline-item
        v-for="(i, num) in tempArr"
        :key="num"
        :hide-timestamp="true"
        placement="top"
      >
        <div class="timeline">{{ i.creationTime }}</div>
        <!-- 文章列表 -->
        <div class="ArticleList">
          <div class="ArticleItem" v-for="(item, index) in i.list" :key="index">
            <router-link
              v-if="item.thumb != ''"
              class="Image"
              to=""
              @click="Details(item.articleId, item.articleTitle)"
            >
              <img
                :src="'https://api.lphadmin.cn/uploads/' + item.thumb"
                alt=""
              />
            </router-link>
            <h2 class="Title">
              <router-link
                to=""
                @click="Details(item.articleId, item.articleTitle)"
                >{{ item.articleTitle }}</router-link
              >
            </h2>
            <div class="Digest">{{ item.articleDigest }}</div>
            <div class="Details">
              <p>
                <span class="icode"
                  ><i class="iconfont">&#xe600;</i>
                  {{ item.creationTime }}</span
                >
                <span class="icode"
                  ><i class="iconfont">&#xe6a7;</i>
                  {{ item.articleClassify }}</span
                >
                <span class="icode">
                  <a v-for="(items, index) in item.articleLable" :key="index">
                    <span v-for="(i, index) in labelList" :key="index">
                      <el-tag
                        v-if="items === i.labelId"
                        :style="{
                          color: i.labelColor,
                          backgroundColor: i.labelBgc,
                          borderColor: i.labelBgc,
                        }"
                        @click="labelClick(i)"
                        >{{ i.labelName }}</el-tag
                      >
                    </span>
                  </a>
                </span>
              </p>
              <a @click="Details(item.articleId, item.articleTitle)"
                >阅读全文</a
              >
            </div>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script setup name="Pigeonhole">
import { getArticle } from "@/api/article";
import { getLabel } from "@/api/label";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import router from "@/router";
const store = useStore();
onMounted(() => {
  getArticleList(); // 获取文章列表
  getLabels(); // 获取标签
});

// 将文章根据相同月份的来合并
const tempArr = ref([]);
const cesMonth = () => {
  ArticleList.value.forEach((item) => {
    let str = item.creationTime.substr(0, 7);
    const exists = tempArr.value.some((obj) => obj.creationTime === str);
    if (!exists) {
      tempArr.value.push({
        creationTime: str,
        list: [item],
      });
    } else {
      tempArr.value.forEach((items) => {
        if (items.creationTime === str) {
          items.list.push(item);
        }
      });
    }
  });
};

// 文章搜索条件
const formArticle = ref({
  limit: 9999,
  page: 1,
  iScarousel: 0,
  Sticky: 0,
});
// 文章列表
const ArticleList = ref([]);
const getArticleList = () => {
  let obj = { ...formArticle.value };
  for (let key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  getArticle(obj).then((res) => {
    if (res.code === 200) {
      ArticleList.value = [];
      res.data.data.forEach((v) => {
        v.articleLable = JSON.parse(v.articleLable);
        ArticleList.value.push(v);
      });
      cesMonth();
    }
  });
};

// 获取标签
const labelList = ref([]);
const getLabels = () => {
  getLabel().then((res) => {
    if (res.code === 200) {
      labelList.value = res.data.data;
    }
  });
};

// 跳转详情
const Details = (id, title) => {
  let obj = {
    id,
    title,
  };
  store.dispatch("sum_setArticleId", obj);
  router.push({
    path: "/details",
    query: {
      id,
    },
  });
};
// label点击
const labelClick = (i) => {
  console.log(i);
  router.push({
    path: "/search",
    query: {
      type: i.labelId,
      typeLable: i.labelName,
    },
  });
};
</script>
<style scoped lang="scss">
.Pigeonhole {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px;
  box-sizing: border-box;
}
.timeline {
  color: #409eff;
  font-size: 20px;
  font-weight: 700;
  // margin-bottom: 8px;
}
:deep(.el-timeline-item__node) {
  background-color: #409eff !important;
  margin-top: 3px;
}
:depp(.el-timeline-item__tail) {
  border-left: 2px solid rgba(64, 158, 255, 0.2) !important;
}
.ArticleList {
  .ArticleItem {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    .Image {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 320px;
        transition: transform 3s ease-out, opacity 0.5s ease-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .Title {
      padding: 0px 0 10px 10px;
      a {
        text-decoration: none;
        font-size: 22px;
        color: #333;
      }
    }
    .Digest {
      color: #333;
      opacity: 0.8;
      font-size: 18px;
      padding: 0 0 0 10px;
    }
    .Details {
      padding: 20px 10px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      opacity: 0.8;
      font-size: 16px;
      p {
        display: flex;
        align-items: center;
        .icode {
          margin-right: 10px;
          a {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
      a {
        cursor: pointer;
        text-decoration: none;
        font-weight: 700;
        color: #409eff;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
